import { useMutation } from "@tanstack/react-query"
import { sendHelpMessage } from "./apiCalls"
import { messageError, messageSuccess } from "../../../services/messages"
type HelpMessageType = {
    topic: string
    description: string
}
export const useSendHelpMutation = () => {
    return useMutation<
        unknown,
        Error,
        HelpMessageType
    >({
        mutationFn: sendHelpMessage,
        onSuccess: () => {
            messageSuccess("Fertig!", "Die Nachricht wurde erfolgreich zugestellt.")
        },
        onError: (err) => {
            messageError("Fehler!", "Die Meldung konnte nicht zugestellt werden. Bitte kontaktieren Sie umgehend die IT-Dienstleister per Telefon oder Mail!")
            console.log(err)
        },
    })
}
