import React, { useCallback, useContext, useMemo, useState } from "react"
import BasePage from "../../../components/layout/BasePage"
import BaseHeadBanner from "../../../components/elements/BaseHeadBanner"
import { getCustomerDataNames } from "../api/apiCalls"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { ModalContext } from "../../../components/contexts/ModalContext"
import BaseFilterButton from "../../../components/elements/BaseFilterButton"
import CustomerFilterModal from "../components/CustomerFilterModal"
import CustomerCard from "../components/CustomerCard"
import Loading from "../../../components/common/Loading"
import ErrorPage from "../../404/ErrorPage"
import ModalCheckAction from "../../../components/elements/ModalCheckAction"
import CustomerActionModal from "../components/CustomerActionModal"
import AddCustomerModal from "../components/AddCustomerModal"
import { useNavigate } from "react-router-dom"
import { Customer } from "../types/Types"
import { preventKeyDown, useDelayWhenSearchWithPagination } from "../../../services/functions"
import { Roles } from "../../../auth/RoleMapping"
import { RenderWhenAuthorized } from "../../../components/authentication/RenderWhenAuthorized"
import { Pagination } from "antd"
import { getPaginatedData } from "../../api/apiCalls"
import { useArchiveDataMutation, useDeleteDataMutation } from "../../api/services"

export default function Kunden() {
    const navigate = useNavigate()

    // variables for pagination
    const [page, setpage] = useState(1)
    const handlePageChange = (page: number) => {
        setpage(page)
    }

    // variables for search in pagination
    const [searchTerm, setsearchTerm] = useState("")
    const searchParams = useDelayWhenSearchWithPagination(searchTerm)

    // Get Data Query
    const { error, data, isLoading } = useQuery({
        queryKey: ["customerData" + page + searchParams],
        queryFn: () => getPaginatedData("customer", page, 20, searchParams),
    })

    const {
        error: errorNames,
        data: dataNames,
        isLoading: isLoadingNames,
    } = useQuery({
        queryKey: ["customerDataNames"],
        queryFn: getCustomerDataNames,
    })

    // NOTE -> Filter Variables
    const [filter, setFilter] = useState(0)
    const [showFilter, setShowFilter] = useState(false)
    const [archived, setarchived] = useState("hidden")
    const modalCon = useContext(ModalContext)

    const handleRemoveFilter = () => {
        setarchived("hidden")
        setFilter(0)
    }

    const handleUseFilter = () => {
        setShowFilter(false)
        setFilter(filter + 1)
    }

    const filterArchived = useCallback(
        (data: Customer[]) => {
            if (archived === "hidden") {
                return data?.filter((item) => item.archived === false)
            } else if (archived === "show") {
                return data
            } else if (archived === "only") {
                return data?.filter((item) => item.archived === true)
            } else {
                return data
            }
        },
        [filter]
    )

    const displayedCustomers = useMemo(() => {
        if (data?.docs) {
            return filterArchived(data.docs)
        }
    }, [filterArchived, data])

    const queryClient = useQueryClient()
    const { mutate: archiveCustomer, isPending: pendingArchiveCustomer } = useArchiveDataMutation(
        queryClient,
        () => modalCon?.trigger(3),
        "customerData" + page,
        "Kundenstatus"
    )

    const [selectedCustomerId, setselectedCustomerId] = useState("")
    const handleArchiveCustomer = (archive: boolean) => {
        archiveCustomer({
            id: selectedCustomerId,
            pageType: "customer",
            body: { archived: archive },
        })
    }

    const { mutate: deleteCustomer, isPending: pendingDeleteCustomer } = useDeleteDataMutation(queryClient, modalCon, "Kunde", "customerData" + page)
    const handleDeleteCustomer = () => {
        deleteCustomer({ id: selectedCustomerId, pageType: "customer" })
    }

    return (
        <BasePage>
            <BaseHeadBanner
                title={"Kunden"}
                button={
                    <RenderWhenAuthorized requiresAll={[Roles.kunden_create]}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-8 h-8 cursor-pointer hidden lg:block"
                            onClick={() => {
                                modalCon?.trigger(1)
                            }}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </RenderWhenAuthorized>
                }
            />
            <section className="max-h-[20vh] w-full flex flex-row justify-between gap-4 py-4 md:px-4 items-center relative">
                <input
                    onKeyDown={preventKeyDown}
                    onChange={(e) => setsearchTerm(e.target.value)}
                    type="text"
                    value={searchTerm ?? null}
                    placeholder="Kunden suchen..."
                    className="w-full max-w-xs rounded-default bg-none shadow-lg p-4 text-base-200"
                />
                <BaseFilterButton func={() => setShowFilter(!showFilter)} />
                <CustomerFilterModal
                    visible={showFilter}
                    setVisible={() => setShowFilter(false)}
                    archived={archived}
                    setarchived={setarchived}
                    filterRemove={handleRemoveFilter}
                    useFilter={handleUseFilter}
                />
            </section>
            {(isLoading || isLoadingNames || pendingArchiveCustomer || pendingDeleteCustomer) && <Loading />}
            {(error || errorNames) && <ErrorPage />}
            {data && dataNames && !isLoading && !error && (
                <>
                    {/* Body with Cards */}
                    <section className="max-h-[75vh] w-full overflow-y-scroll inline-grid md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4 pb-8 md:px-4">
                        {displayedCustomers?.map((item: any) => (
                            <CustomerCard
                                bills={item.billCount}
                                offers={item.offerCount}
                                objects={item.objectCount}
                                key={item._id}
                                name={item.customer?.name}
                                owner={item.administrator?.name}
                                adress={item.customer?.adress}
                                archived={item.archived}
                                openFunc={() => {
                                    navigate("/kunden/" + item._id)
                                }}
                                archiveFunc={() => {
                                    setselectedCustomerId(item._id)
                                    item.archived ? modalCon?.trigger(5) : modalCon?.trigger(3)
                                }}
                                deleteFunc={() => {
                                    setselectedCustomerId(item._id)
                                    modalCon?.trigger(4)
                                }}
                            />
                        ))}
                    </section>
                    {(!displayedCustomers || displayedCustomers.length === 0) && (
                        <div className="flex flex-col justify-center items-center w-full">
                            <p className="text-gray-300 uppercase font-medium pt-4">Keine Kunden angelegt</p>
                            <button className="btn btn-ghost mt-2 btn-outline max-w-xs" onClick={() => setsearchTerm("")}>
                                Eingabe zurücksetzen
                            </button>
                        </div>
                    )}
                    <div className="h-[5vh] flex justify-center w-full p-4">
                        <Pagination
                            defaultCurrent={1}
                            pageSize={20}
                            current={page}
                            onChange={handlePageChange}
                            total={data.totalDocs}
                            showSizeChanger={false}
                        />
                    </div>
                    <AddCustomerModal names={dataNames} />
                    <CustomerActionModal
                        objectAction={() => {
                            modalCon?.trigger(2)
                            modalCon?.trigger(9)
                        }}
                    />
                    <ModalCheckAction
                        modalId={3}
                        headline={"Wirklich Archivieren?"}
                        text={
                            "Sind Sie sich sicher, diesen Kunden archivieren wollen? Er wird nicht mehr in der Liste Ihrer aktiven Kunden angezeigt werden."
                        }
                        buttonText={"Archivieren"}
                        buttonColor="bg-blue-300 border-none hover:bg-blue-400"
                        func={() => handleArchiveCustomer(true)}
                    />
                    <ModalCheckAction
                        modalId={5}
                        headline={"Archivieren rückgängig machen?"}
                        text={
                            "Sind Sie sich sicher, diesen Kunden nicht mehr archivieren wollen? Er wird ab sofort wieder in der Liste Ihrer aktiven Kunden angezeigt."
                        }
                        buttonText={"Anzeigen"}
                        buttonColor="bg-blue-300 border-none hover:bg-blue-400"
                        func={() => handleArchiveCustomer(false)}
                    />
                    <ModalCheckAction
                        modalId={4}
                        headline={"Wirklich Löschen?"}
                        text={"Sind Sie sich sicher, diesen Kunden zu löschen? Alle dazugehörigen Daten werden ebenfalls gelöscht."}
                        buttonText={"Löschen"}
                        buttonColor="btn-error"
                        func={handleDeleteCustomer}
                    />
                </>
            )}
        </BasePage>
    )
}
