import React, { useCallback, useContext, useMemo, useState } from "react"
import BasePage from "../../../components/layout/BasePage"
import BaseHeadBanner from "../../../components/elements/BaseHeadBanner"
import { ModalContext } from "../../../components/contexts/ModalContext"
import { useNavigate } from "react-router-dom"
import BaseFilterButton from "../../../components/elements/BaseFilterButton"
import OfferFilterModal from "../components/offer/OfferFilterModal"
import dayjs from "dayjs"
import OfferTableRow from "../components/offer/OfferTableRow"
import { useQuery } from "@tanstack/react-query"
import Loading from "../../../components/common/Loading"
import ErrorPage from "../../404/ErrorPage"
import AddOfferModal from "../components/offerModal/AddOfferModal"
import { OfferGet } from "../../../data/Types"
import { preventKeyDown, useDelayWhenSearchWithPagination } from "../../../services/functions"
import { Roles } from "../../../auth/RoleMapping"
import { RenderWhenAuthorized } from "../../../components/authentication/RenderWhenAuthorized"
import { Pagination } from "antd"
import { getPaginatedData } from "../../api/apiCalls"

export default function OfferPage() {
    // variables for pagination
    const [page, setpage] = useState(1)
    const handlePageChange = (page: number) => {
        setpage(page)
    }

    // variables for search in pagination
    const [searchTerm, setsearchTerm] = useState("")
    const searchParams = useDelayWhenSearchWithPagination(searchTerm)

    // Get Data Query
    const { error, data, isLoading } = useQuery({
        queryKey: ["offerData" + page + searchParams],
        queryFn: () => getPaginatedData("offer", page, 15, searchParams),
    })

    const navigate = useNavigate()
    const modalCon = useContext(ModalContext)
    const [sort, setsort] = useState(false)

    // NOTE Filter used Variables
    const [showFilter, setshowFilter] = useState(false)
    const [filter, setFilter] = useState(0)
    const [filterStatus, setfilterStatus] = useState("")
    const [filterDateSince, setfilterDateSince] = useState("")
    const [filterDateTo, setfilterDateTo] = useState("")
    const [archived, setarchived] = useState("hidden")

    const handleChange = useCallback((textContent: string) => {
        setfilterStatus(textContent)
    }, [])

    const handleRemoveFilter = () => {
        setfilterStatus("")
        setfilterDateTo("")
        setfilterDateSince("")
        setarchived("hidden")
        setFilter(0)
    }

    const handleUseFilter = () => {
        setshowFilter(false)
        setFilter(filter + 1)
    }

    // sort algo
    const sortDates = useCallback(
        (data: OfferGet[]) => {
            if (sort) {
                return data?.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
            } else {
                return data?.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
            }
        },
        [sort]
    )

    const filterDates = useCallback(
        (data: OfferGet[]) => {
            if (filterDateSince === "" && filterDateTo === "") {
                return data
            } else {
                const returnData = data?.filter((item) => {
                    const itemDate = dayjs(item.date)
                    const isAfterStartDate = filterDateSince !== "" ? itemDate.isAfter(dayjs(filterDateSince)) : true
                    const isBeforeEndDate = filterDateTo !== "" ? itemDate.isBefore(dayjs(filterDateTo)) : true
                    return isAfterStartDate && isBeforeEndDate
                })
                return returnData
            }
        },
        [filter]
    )

    const filterData = useCallback(
        (data: OfferGet[]) => {
            if (filter) {
                return data?.filter((item) => filterStatus === "" || filterStatus === item.status)
            } else {
                return data
            }
        },
        [filter]
    )

    const filterArchived = useCallback(
        (data: OfferGet[]) => {
            if (archived === "hidden") {
                return data?.filter((item) => item.archived === false)
            } else if (archived === "show") {
                return data
            } else if (archived === "only") {
                return data?.filter((item) => item.archived === true)
            } else {
                return data
            }
        },
        [filter]
    )

    const displayedOffers = useMemo(() => {
        if (data?.docs) {
            const sortData = sortDates(data.docs)
            const archivedData = filterArchived(sortData)
            const filteredData = filterData(archivedData)
            return filterDates(filteredData)
        }
    }, [sortDates, filterData, filterDates, isLoading, error, data])
    
    return (
        <BasePage>
            <BaseHeadBanner
                title={"Angebote"}
                button={
                    <RenderWhenAuthorized requiresAll={[Roles.buchhaltung_read_write]}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-8 h-8 cursor-pointer hidden md:block"
                            onClick={() => modalCon?.trigger(1)}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </RenderWhenAuthorized>
                }
            />
            {/* Button Row */}
            <section className="w-full flex flex-wrap justify-between gap-4 py-4 md:px-4 items-center relative  max-h-[18vh]">
                <input
                    onKeyDown={preventKeyDown}
                    onChange={(e) => setsearchTerm(e.target.value)}
                    type="text"
                    value={searchTerm ?? null}
                    placeholder="Nach Kunden suchen..."
                    className="w-full max-w-xs rounded-default bg-none shadow-lg p-4 text-base-200"
                />
                <div className="flex flex-row gap-4">
                    <button className="btn bg-white shadow-lg border-none hover:bg-white/40" onClick={() => navigate("/buchhaltung/rechnungen")}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                            />
                        </svg>
                        Rechnungen
                    </button>
                    <button
                        className="hidden sm:flex btn bg-white shadow-lg border-none hover:bg-white/40"
                        onClick={() => navigate("/buchhaltung/zahlungen")}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z"
                            />
                        </svg>
                        Zahlungen
                    </button>
                    <button
                        className="hidden sm:flex btn bg-white shadow-lg border-none hover:bg-white/40"
                        onClick={() => {
                            setsort(!sort)
                        }}>
                        {sort ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18" />
                            </svg>
                        ) : (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3" />
                            </svg>
                        )}
                        Sortieren
                    </button>
                    <BaseFilterButton func={() => setshowFilter(!showFilter)} />
                    <OfferFilterModal
                        visible={showFilter}
                        dateSince={filterDateSince}
                        dateTo={filterDateTo}
                        changeDateTo={setfilterDateTo}
                        changeDateSince={setfilterDateSince}
                        useFilter={handleUseFilter}
                        filterStatus={filterStatus}
                        filterChange={handleChange}
                        filterRemove={handleRemoveFilter}
                        setVisible={() => setshowFilter(!showFilter)}
                        archived={archived}
                        setarchived={setarchived}
                    />
                </div>
            </section>
            {isLoading && <Loading />}
            {error && <ErrorPage />}
            {data && !isLoading && !error && (
                <>
                    {/* TABLE */}
                    <section className="w-full md:px-4 h-[65vh] xl:max-h-[75vh] overflow-x-hidden">
                        <table className="table table-md table-pin-rows bg-white shadow-lg">
                            <thead>
                                <tr className=" h-12 bg-gray-200 border-none">
                                    <th className=" text-base w-12 h-full items-center justify-center"></th>
                                    <th className=" hidden sm:table-cell text-base min-w-[10rem] w-fit text-center">Datum</th>
                                    <th className=" text-base w-fit text-center">{window.innerWidth > 640 ? "Angebotsnummer" : "Nr."}</th>
                                    <th className=" text-base min-w-[8rem] w-fit text-center">Betrag</th>
                                    <th className="hidden md:table-cell text-base w-fit text-center">Kunde</th>
                                    <th className="hidden lg:table-cell text-base min-w-[10rem] w-fit text-center">Status</th>
                                </tr>
                            </thead>
                            <tbody className="overflow-y-scroll">
                                {displayedOffers?.map((item) => (
                                    <OfferTableRow
                                        key={item._id}
                                        id={item._id}
                                        title={"Angebot " + item.nr}
                                        amount={
                                            item.positions
                                                .reduce((acc: any, curr: any) => {
                                                    return acc + curr.singlePrice * curr.amount
                                                }, 0)
                                                .toFixed(2) + ""
                                        }
                                        customer={item?.customer_id?.customer?.name ?? ""}
                                        status={item.status}
                                        date={item.date}
                                        archived={item.archived}
                                    />
                                ))}
                            </tbody>
                        </table>
                        {(!displayedOffers || displayedOffers.length === 0) && (
                            <p className="text-center text-gray-300 uppercase font-medium pt-4">Keine erstellten Angebote</p>
                        )}
                    </section>
                    {/* PAGINATION */}
                    <div className="flex justify-center w-full p-4">
                        <Pagination
                            defaultCurrent={1}
                            pageSize={15}
                            current={page}
                            onChange={handlePageChange}
                            total={data.totalDocs}
                            showSizeChanger={false}
                        />
                    </div>
                </>
            )}
            <AddOfferModal />
        </BasePage>
    )
}
