import { QueryClient, useMutation } from "@tanstack/react-query"
import { Customer } from "../types/Types"
import {
    deleteAdministrator,
    deleteDocFromCustomer,
    postNewDocToCustomer,
    updateCustomer,
} from "./apiCalls"
import { messageError, messageSuccess } from "../../../services/messages"

// delete administrator
export const useDeleteAdministratorMutation = (queryClient: QueryClient, setdeleteAdminScreen: (e: boolean) => void, setchoosenAdmin: () => void) => {
    return useMutation<unknown, Error, string>({
        mutationFn: deleteAdministrator,
        onSuccess: () => {
            messageSuccess("Fertig!", "Der Verwalter wurde erfolgreich gelöscht.")
            setdeleteAdminScreen(false)
            setchoosenAdmin()
            queryClient.invalidateQueries({
                queryKey: ["administratorData"],
            })
        },
        onError: (err) => {
            messageError("Fehler!", "Der Verwalter konnte nicht gelöscht werden.")
            console.log(err)
        },
    })
}

// update customer data
export const useUpdateCustomerMutation = (queryClient: QueryClient) => {
    return useMutation<unknown, Error, { id: string; body: Customer }>({
        mutationFn: updateCustomer,
        onSuccess: (data, variables) => {
            messageSuccess("Fertig!", "Der Kunde wurde erfolgreich aktualisiert.")
            queryClient.invalidateQueries({
                queryKey: ["singleCustomerData" + variables.id],
            })
        },
        onError: (err) => {
            messageError("Fehler!", "Der Kunde konnte nicht aktualisiert werden.")
            console.log(err)
        },
    })
}

// upload document(s) to customer
export const useAddDocToCustomerMutation = (queryClient: QueryClient) => {
    return useMutation<unknown, Error, { id: string; data: any }>({
        mutationFn: postNewDocToCustomer,
        onSuccess: (data, variables) => {
            messageSuccess("Fertig!", "Das Dokument wurde erfolgreich hinzugefügt.")
            queryClient.invalidateQueries({
                queryKey: ["singleCustomerData" + variables.id],
            })
        },
        onError: (err) => {
            messageError("Fehler!", "Das Dokument konnte nicht hinzugefügt werden.")
            console.log(err)
        },
    })
}

// delete doc-data from customer & from s3
export const usePatchDocCustomerMutation = (queryClient: QueryClient, modalCon: any, modalConId: number) => {
    return useMutation<unknown, Error, { id: string; s3Key: string }>({
        mutationFn: deleteDocFromCustomer,
        onSuccess: (data, variables) => {
            messageSuccess("Fertig!", "Das Dokument wurde erfolgreich gelöscht.")
            modalCon?.trigger(modalConId)
            queryClient.invalidateQueries({
                queryKey: ["singleCustomerData" + variables.id],
            })
        },
        onError: (err) => {
            messageError("Fehler!", "Das Dokument konnte nicht gelöscht werden.")
            console.log(err)
        },
    })
}
