import React, { useState } from "react"
import BasePage from "../../../components/layout/BasePage"
import BaseHeadBanner from "../../../components/elements/BaseHeadBanner"
import BaseInputField from "../../../components/elements/BaseInputField"
import BaseInputArea from "../../../components/elements/BaseInputArea"
import { useSendHelpMutation } from "../api/services"

export default function Hilfe() {
    const { isSuccess, mutate, reset } = useSendHelpMutation()
    const [topic, settopic] = useState("")
    const [description, setdescription] = useState("")

    const sendMessage = (e: any) => {
        e.preventDefault()
        mutate({ topic, description })
    }

    return (
        <BasePage>
            <BaseHeadBanner title={"Hilfe"} />
            <form>
                <div className="w-full rounded-default shadow-lg mt-6 bg-white p-4 flex flex-col items-center">
                    <BaseInputField label={"Thema"} type={"text"} value={topic} onChange={settopic} disabled={isSuccess} required />
                    <BaseInputArea
                        label={"Beschreibung"}
                        value={description}
                        onChange={setdescription}
                        minRows={6}
                        maxRows={10}
                        classProps="pt-2"
                        disabled={isSuccess}
                        required
                    />
                    <div className="flex flex-row justify-end w-full pt-4">
                        <span className="text-sm text-gray-500 ">{isSuccess ? "Nachricht wurde erfolgreich gesendet" : ""}</span>
                    </div>
                    <div className="flex flex-row justify-end w-full pt-2 gap-4">
                        {isSuccess && (
                            <div>
                                <button
                                    className="btn btn-accent"
                                    onClick={() => {
                                        reset()
                                        settopic("")
                                        setdescription("")
                                    }}>
                                    Neue Nachricht
                                </button>
                            </div>
                        )}
                        <button type="submit" className="btn btn-success" onClick={sendMessage} disabled={isSuccess}>
                            Abschicken
                        </button>
                    </div>
                </div>
            </form>
        </BasePage>
    )
}
