import { QueryClient, useMutation } from "@tanstack/react-query"
import { ObjectType } from "../types/types"
import {
    addImageToObject,
    createObjectServiceContract,
    deleteDocFromObject,
    postNewDocToObject,
    updateListOfServicesOfObject,
    updateObject,
} from "./apiCalls"
import { useContext } from "react"
import { ModalContext } from "../../../components/contexts/ModalContext"
import { messageError, messageSuccess } from "../../../services/messages"

// update object data
export const useUpdateObjectMutation = (queryClient: QueryClient) => {
    return useMutation<unknown, Error, { id: string; body: ObjectType }>({
        mutationFn: updateObject,
        onSuccess: (data, variables) => {
            messageSuccess("Fertig!", "Das Objekt wurde erfolgreich aktualisiert.")
            queryClient.invalidateQueries({
                queryKey: ["singleObjectData" + variables.id],
            })
        },
        onError: (err) => {
            messageError("Fehler!", "Das Objekt konnte nicht aktualisiert werden.")
            console.log(err)
        },
    })
}

// update list of services of object
export const useUpdateListOfServiesOfObjectMutation = (queryClient: QueryClient, modalConId: number) => {
    const modalCon = useContext(ModalContext)
    return useMutation<
        unknown,
        Error,
        {
            id: string
            serviceList: any
        }
    >({
        mutationFn: updateListOfServicesOfObject,
        onSuccess: (data, variables) => {
            messageSuccess("Fertig!", "Das Leistungsverzeichnis wurde erfolgreich gespeichert.")
            modalCon?.trigger(modalConId)
            queryClient.invalidateQueries({
                queryKey: ["singleObjectData" + variables.id],
            })
        },
        onError: (err) => {
            messageError("Fehler!", "Das Leistungsverzeichnis konnte nicht gespeichert werden.")
            console.log(err)
        },
    })
}

// create service object contract
export const useCreateObjectContractMutation = (queryClient: QueryClient, modalConId: number) => {
    const modalCon = useContext(ModalContext)
    return useMutation<
        unknown,
        Error,
        {
            obj: string
            cus: string
            contract_start: string
            price: number
        }
    >({
        mutationFn: createObjectServiceContract,
        onSuccess: (data, variables) => {
            messageSuccess("Fertig!", "Der Objektbetreuungsvertrag wurde erfolgreich erstellt.")
            modalCon?.trigger(modalConId)
            queryClient.invalidateQueries({
                queryKey: ["singleObjectData" + variables.obj],
            })
        },
        onError: (err) => {
            messageError("Fehler!", "Der Objektbetreuungsvertrag konnte nicht erstellt werden.")
            console.log(err)
        },
    })
}

// upload document(s) to object
export const useAddDocToObjectMutation = (queryClient: QueryClient) => {
    return useMutation<unknown, Error, { id: string; data: any }>({
        mutationFn: postNewDocToObject,
        onSuccess: (data, variables) => {
            messageSuccess("Fertig!", "Das Dokument wurde erfolgreich hinzugefügt.")
            queryClient.invalidateQueries({
                queryKey: ["singleObjectData" + variables.id],
            })
        },
        onError: (err) => {
            messageError("Fehler!", "Das Dokument konnte nicht hinzugefügt werden.")
            console.log(err)
        },
    })
}

// delete doc-data from object & from s3
export const usePatchDocObjectMutation = (queryClient: QueryClient, modalCon: any, modalConId: number) => {
    return useMutation<unknown, Error, { id: string; file: string }>({
        mutationFn: deleteDocFromObject,
        onSuccess: (data, variables) => {
            messageSuccess("Fertig!", "Das Dokument wurde erfolgreich gelöscht.")
            modalCon?.trigger(modalConId)
            queryClient.invalidateQueries({
                queryKey: ["singleObjectData" + variables.id],
            })
        },
        onError: (err) => {
            messageError("Fehler!", "Das Dokument konnte nicht gelöscht werden.")
            console.log(err)
        },
    })
}

// NOTE still in progress
// upload image - general?
export const useAddImageToObjectMutation = (queryClient: QueryClient, setLoading: (e: boolean) => void) => {
    return useMutation<unknown, Error, { id: string; img: any }>({
        mutationFn: addImageToObject,
        onSuccess: (data, variables) => {
            setLoading(false)
            messageSuccess("Fertig!", "Upload erfolgreich durchgeführt.")
            queryClient.invalidateQueries({
                queryKey: ["singleObjectImage" + variables.id],
            })
        },
        onError: (err) => {
            messageError("Fehler!", "Der Upload ist fehlgeschlagen.")
            setLoading(false)
            console.log(err)
        },
    })
}
