import { useContext } from "react"
import { Roles } from "../../../../auth/RoleMapping"
import { RenderWhenAuthorized } from "../../../../components/authentication/RenderWhenAuthorized"
import { ModalContext } from "../../../../components/contexts/ModalContext"
import BaseInputField from "../../../../components/elements/BaseInputField"
import BaseDivider from "../../../../components/elements/BaseDivider"
import { DocumentDataType } from "../../../../components/Types"
import BaseDocumentElement from "../../../../components/elements/BaseDocumentElement"
import { adressType } from "../../../../data/Types"
import BaseInputArea from "../../../../components/elements/BaseInputArea"
import { SidePanelContext } from "../../../../components/contexts/SidePanelContext"
import { useKeycloak } from "@react-keycloak/web"

type ObjectDetailPartRightType = {
    unitperentrance: string
    setunitperentrance: (e: string) => void
    amount: string
    setAmount: (e: string) => void
    propertyArea: string
    setpropertyArea: (e: string) => void
    contractStart: string
    setcontractStart: (e: string) => void
    contractEnd: string
    setcontractEnd: (e: string) => void
    lastPriceChange: string
    setlastPriceChange: (e: string) => void
    notes: string
    setnotes: (e: string) => void
    documents?: DocumentDataType[]
    lastContractChange: string
    setlastContractChange: (e: string) => void
    adress: adressType
    setadress: (e: any) => void
    handleDownload: (e: string, f: string) => void
    setfileToDelete: (e: string) => void
    handleUpdateObject: () => void
    setselectedS3Key: (e: string) => void
}

export default function ObjectDetailPartRight(props: ObjectDetailPartRightType) {
    const { keycloak } = useKeycloak()
    const modalCon = useContext(ModalContext)
    const sidePanelCon = useContext(SidePanelContext)
    const obvFound = props.documents?.find((item) => item?.tags?.includes("Objektbetreuungsvertrag"))
    console.log(props.documents)
    return (
        <div className="flex flex-col w-full bg-white rounded-default shadow-lg p-4 overflow-y-scroll h-[80vh]">
            <div className="flex flex-col lg:flex-row gap-2 lg:gap-4 lg:items-end">
                <BaseInputField
                    disabled={!keycloak.hasRealmRole(Roles.objekte_read)}
                    label={"Einheiten pro Eingang"}
                    type={"number"}
                    value={props.unitperentrance}
                    onChange={props.setunitperentrance}
                />
                <BaseInputField
                    disabled={!keycloak.hasRealmRole(Roles.objekte_read)}
                    label={"Gesamt"}
                    type={"number"}
                    value={props.amount}
                    onChange={props.setAmount}
                />
                <BaseInputField
                    disabled={!keycloak.hasRealmRole(Roles.objekte_read)}
                    label={"Grundstücksfläche im m²"}
                    type={"number"}
                    value={props.propertyArea}
                    onChange={props.setpropertyArea}
                />
            </div>
            <BaseInputField
                disabled={!keycloak.hasRealmRole(Roles.objekte_read)}
                classProps="pt-2"
                label={"Vertragsbeginn"}
                type={"date"}
                value={props.contractStart}
                onChange={props.setcontractStart}
            />
            <BaseInputField
                disabled={!keycloak.hasRealmRole(Roles.objekte_read)}
                classProps="pt-2"
                label={"Vertragsende"}
                type={"date"}
                value={props.contractEnd}
                onChange={props.setcontractEnd}
            />
            <BaseInputField
                disabled={!keycloak.hasRealmRole(Roles.objekte_read)}
                classProps="pt-2"
                label={"Letzte Preisanpassung"}
                type={"date"}
                value={props.lastPriceChange}
                onChange={props.setlastPriceChange}
            />
            <BaseInputField
                disabled={!keycloak.hasRealmRole(Roles.objekte_read)}
                classProps="pt-2"
                label={"Letzte Vertragsänderung"}
                type={"date"}
                value={props.lastContractChange}
                onChange={props.setlastContractChange}
            />
            <BaseInputArea
                label={"Bemerkung"}
                classProps="pt-2"
                value={props.notes}
                disabled={!keycloak.hasRealmRole(Roles.objekte_read)}
                onChange={props.setnotes}
            />
            <BaseDivider classProps="mt-6 mb-2" />
            <div className="flex flex-row gap-4 pt-2">
                <BaseInputField
                    disabled={!keycloak.hasRealmRole(Roles.objekte_read)}
                    label={"Straße *"}
                    type={"text"}
                    value={props.adress?.street}
                    onChange={(newStreet) =>
                        props.setadress((prevAdress: any) => {
                            return {
                                ...prevAdress,
                                street: newStreet,
                            }
                        })
                    }
                />
                <BaseInputField
                    disabled={!keycloak.hasRealmRole(Roles.objekte_read)}
                    label={"Nummer *"}
                    width="w-48"
                    type={"text"}
                    value={props.adress?.nr}
                    onChange={(newNr) =>
                        props.setadress((prevAdress: any) => {
                            return {
                                ...prevAdress,
                                nr: newNr,
                            }
                        })
                    }
                />
            </div>
            <div className="flex flex-row gap-4 pt-2">
                <BaseInputField
                    disabled={!keycloak.hasRealmRole(Roles.objekte_read)}
                    label={"PLZ *"}
                    width="w-64"
                    type={"text"}
                    value={props.adress?.plz}
                    onChange={(newPlz) =>
                        props.setadress((prevAdress: any) => {
                            return {
                                ...prevAdress,
                                plz: newPlz,
                            }
                        })
                    }
                />
                <BaseInputField
                    disabled={!keycloak.hasRealmRole(Roles.objekte_read)}
                    label={"Stadt *"}
                    type={"text"}
                    value={props.adress?.city}
                    onChange={(newCity) =>
                        props.setadress((prevAdress: any) => {
                            return {
                                ...prevAdress,
                                city: newCity,
                            }
                        })
                    }
                />
            </div>
            <BaseDivider classProps="mt-6 mb-2" />
            {obvFound && (
                <>
                    <div className="flex flex-col pt-2">
                        <label className="text-sm pl-2 pb-1">Vertrag zum Objekt</label>
                        <BaseDocumentElement
                            obv
                            title={obvFound.title}
                            roles={[Roles.objekte_read_write]}
                            timestamp={obvFound.timestamp}
                            downloadFunc={() => props.handleDownload(obvFound.s3Key, obvFound.title)}
                            deleteFunc={() => {
                                props.setfileToDelete(obvFound.s3Key)
                                modalCon?.trigger(4)
                            }}
                            showPreviewFunc={() => {
                                props.setselectedS3Key(obvFound.s3Key)
                                sidePanelCon?.trigger(6)
                            }}
                        />
                    </div>
                    <BaseDivider classProps="mt-2 mb-2" />
                </>
            )}
            <div className="flex flex-col pt-2">
                <label className="text-sm pl-2 pb-1">Dokumente</label>
                {props.documents?.map((item: DocumentDataType, index: number) => (
                    <BaseDocumentElement
                        key={index}
                        title={item.title}
                        roles={[Roles.objekte_read_write]}
                        timestamp={item.timestamp}
                        downloadFunc={() => props.handleDownload(item.s3Key, item.title)}
                        deleteFunc={() => {
                            props.setfileToDelete(item.s3Key)
                            modalCon?.trigger(4)
                        }}
                        showPreviewFunc={() => {
                            props.setselectedS3Key(item.s3Key)
                            sidePanelCon?.trigger(6)
                        }}
                    />
                ))}
                {props.documents?.length === 0 && <p className="pl-4 text-xs">Keine Dokumente vorhanden</p>}
                <RenderWhenAuthorized requiresAll={[Roles.objekte_read_write]}>
                    <button
                        className="hidden md:block btn btn-sm bg-base-100 shadow-lg border-none hover:bg-white/40 mt-2 mb-4 mr-4 self-end"
                        onClick={() => modalCon?.trigger(3)}>
                        Dokument hinzufügen
                    </button>
                </RenderWhenAuthorized>
            </div>
            <button className="md:hidden btn btn-success mt-4" onClick={props.handleUpdateObject}>
                Speichern
            </button>
        </div>
    )
}
