import { QueryClient, useMutation } from "@tanstack/react-query"
import { Employee } from "../types/Types"
import {
    deleteDocFromEmployee,
    postNewDocToEmployee,
    resetPWFromEmployee,
    updateEmployee,
    updateEmployeeKcGroup,
} from "./apiCalls"
import { messageError, messageSuccess } from "../../../services/messages"

// update employee data
export const useUpdateEmployeeMutation = (queryClient: QueryClient, id: string) => {
    return useMutation<unknown, Error, { id: string; body: Employee }>({
        mutationFn: updateEmployee,
        onSuccess: (data, variables) => {
            messageSuccess("Fertig!", "Der Mitarbeiter wurde erfolgreich aktualisiert.")
            queryClient.invalidateQueries({ queryKey: ["employeeData" + id] })
            queryClient.invalidateQueries({ queryKey: ["employeeData"] })
        },
        onError: (err) => {
            messageError("Fehler!", "Der Mitarbeiter konnte nicht aktualisiert werden.")
            console.log(err)
        },
    })
}

// update employee kc group
export const useUpdateEmployeeKcGroupMutation = (queryClient: QueryClient, id: string) => {
    return useMutation<unknown, Error, { kcid: string; gid: string }>({
        mutationFn: updateEmployeeKcGroup,
        onSuccess: (data, variables) => {
            messageSuccess("Fertig!", "Der Mitarbeiter wurde erfolgreich aktualisiert.")
            queryClient.invalidateQueries({ queryKey: ["employeeData" + id] })
            queryClient.invalidateQueries({ queryKey: ["employeeData"] })
        },
        onError: (err) => {
            messageError("Fehler!", "Der Mitarbeiter konnte nicht aktualisiert werden.")
            console.log(err)
        },
    })
}

// reset pw from user
export const usePWResetEmployeeMutation = (queryClient: QueryClient, modalCon: any, id: string) => {
    return useMutation<unknown, Error, string>({
        mutationFn: resetPWFromEmployee,
        onSuccess: (data, variables) => {
            messageSuccess("Fertig!", "Das Passwort des Mitarbeiters wurde erfolgreich aktualisiert.")
            modalCon?.trigger(7)
            queryClient.invalidateQueries({ queryKey: ["employeeData" + id] })
        },
        onError: (err) => {
            messageError("Fehler!", "Das Passwort des Mitarbeiters konnte nicht aktualisiert werden.")
            console.log(err)
        },
    })
}

// upload document(s) to employee
export const useAddDocToEmployeeMutation = (queryClient: QueryClient, id: string) => {
    return useMutation<unknown, Error, { id: string; data: any }>({
        mutationFn: postNewDocToEmployee,
        onSuccess: () => {
            messageSuccess("Fertig!", "Das Dokument wurde erfolgreich hinzugefügt.")
            queryClient.invalidateQueries({ queryKey: ["employeeData" + id] })
        },
        onError: (err) => {
            messageError("Fehler!", "Das Dokument konnte nicht hinzugefügt werden.")
            console.log(err)
        },
    })
}

// delete doc-data from employee & from s3
export const usePatchDocEmployeeMutation = (queryClient: QueryClient, modalCon: any, modalConId: number, id: string) => {
    return useMutation<unknown, Error, { id: string; file: string }>({
        mutationFn: deleteDocFromEmployee,
        onSuccess: () => {
            messageSuccess("Fertig!", "Das Dokument wurde erfolgreich gelöscht.")
            modalCon?.trigger(modalConId)
            queryClient.invalidateQueries({ queryKey: ["employeeData" + id] })
        },
        onError: (err) => {
            messageError("Fehler!", "Das Dokument konnte nicht gelöscht werden.")
            console.log(err)
        },
    })
}
