import dayjs from "dayjs"
import BaseModal from "../../../../../components/layout/BaseModal"
import { useContext, useEffect, useMemo, useState } from "react"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { ModalContext } from "../../../../../components/contexts/ModalContext"
import { useUpdateTransactionMutation, useUpdateTransactionPullMutation } from "../../../../Dashboard/api/services"
import ErrorPage from "../../../../404/ErrorPage"
import Loading from "../../../../../components/common/Loading"
import { transactionElementType } from "../../../../Dashboard/types"
import { AssignBillElement } from "./AssignBillElement"
import { TransactionRow } from "./TransactionRow"
import { RecheckPaymentModalType } from "../../../types"
import BaseSearchInputField from "../../../../../components/elements/BaseSearchInputField"
import { getPaginatedData } from "../../../../api/apiCalls"

export default function RecheckPaymentModal(props: RecheckPaymentModalType) {
    const { data, isLoading, error } = useQuery({
        queryKey: ["transactionData"],
        queryFn: () => getPaginatedData("banking", 1, 20),
    })
    const [furtherPayment, setfurtherPayment] = useState(false)
    const [showAll, setshowAll] = useState(false)
    const queryClient = useQueryClient()
    const modalCon = useContext(ModalContext)
    const { mutate: assignTransaction, isPending: pendingAssignTransaction } = useUpdateTransactionMutation(queryClient, modalCon)
    const handleClick = (tid: string) => {
        setshowAll(false)
        assignTransaction({
            pid: props.payment?._id ?? "",
            tid: tid,
        })
    }
    const { mutate: unassignTransaction, isPending: pendingUnassignTransaction } = useUpdateTransactionPullMutation(queryClient, modalCon)
    const handleRemove = (tid: string) => {
        setshowAll(false)
        unassignTransaction({
            pid: props.payment?._id ?? "",
            tid: tid,
        })
    }

    useEffect(() => {
        if (data?.docs) {
            data?.docs?.sort(
                (a: transactionElementType, b: transactionElementType) =>
                    new Date(b.bookingTimestamp).getTime() - new Date(a.bookingTimestamp).getTime()
            )
        }
    }, [data])

    // NOTE currently only search function for customer - maybe for bill title needed as well?
    const [searchTerm, setsearchTerm] = useState("")
    const displayedTransactions = useMemo(() => {
        if (!searchTerm && data?.docs) {
            return data?.docs?.filter((item: any) => item?.paymentInformation.remittanceText.toLowerCase()?.includes(searchTerm?.toLowerCase()))
        } else if (data) {
            return data?.docs?.filter((item: any) => item?.paymentInformation.remittanceText.toLowerCase()?.includes(searchTerm?.toLowerCase()))
        }
    }, [data, searchTerm])

    return (
        <BaseModal title="Zahlungen verwalten" modalId={2}>
            {error && <ErrorPage />}
            {(isLoading || pendingAssignTransaction || pendingUnassignTransaction) && <Loading />}
            {props.payment && data && !error && !isLoading && (
                <div className="flex flex-col">
                    <div className="flex flex-row gap-8 p-4 border-b w-full">
                        <div className="flex flex-col justify-between">
                            <p className="text-sm text-gray-500">Zahlung für</p>
                            <p className="font-medium">Rechnung {props.payment.bill_id.nr}</p>
                        </div>
                        <div className="flex flex-col justify-between">
                            <p className="text-sm text-gray-500">Typ</p>
                            <p className="font-medium">{props.payment.bill_id.billType}</p>
                        </div>
                        <div className="flex flex-col justify-between">
                            <p className="text-sm text-gray-500">Betrag</p>
                            <p className="font-medium">{props.payment.expected_payment.toString().replace(".", ",")} €</p>
                        </div>
                        <div className="flex flex-col justify-between">
                            <p className="text-sm text-gray-500">ausgestellt am</p>
                            <p className={`font-medium`}>{dayjs(props.payment.invoice_date).format("DD.MM.YYYY")}</p>
                        </div>
                        <div className="flex flex-col justify-between">
                            <p className="text-sm text-gray-500">fällig am</p>
                            <p className={`font-medium`}>{dayjs(props.payment.due_date).format("DD.MM.YYYY")}</p>
                        </div>
                        <div className="flex flex-col justify-between">
                            <p className="text-sm text-gray-500">Status</p>
                            <p className={`font-medium ${props.payment.status === "settled" ? "text-green-600" : "text-red-600"}`}>
                                {props.payment.status === "settled" ? "Beglichen" : "Offen"}
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col p-4 border-b w-full">
                        {props.payment.transactions.length === 0 && <p className="text-center font-medium">Keine Zahlung zugeordnet</p>}
                        {data
                            .filter((obj: { _id: string }) => props.payment?.transactions.includes(obj._id))
                            .map((item: transactionElementType, index: number) => (
                                <TransactionRow transaction={item} key={index} remove={() => handleRemove(item._id)} />
                            ))}
                    </div>
                    {!furtherPayment && (
                        <button className="btn btn-primary btn-sm mt-2" onClick={() => setfurtherPayment(!furtherPayment)}>
                            Weitere Transaktion zuweisen
                        </button>
                    )}
                    {furtherPayment && (
                        <>
                            <div className="flex justify-between w-full pt-2 items-center py-2">
                                <BaseSearchInputField
                                    small
                                    placeholder="Nach Verwendungszweck suchen"
                                    searchFunction={setsearchTerm}
                                    value={searchTerm}
                                />
                                <div className="flex gap-2">
                                    <button onClick={() => setshowAll(!showAll)} className="btn btn-primary btn-sm">
                                        alle anzeigen
                                    </button>
                                    <button onClick={() => setfurtherPayment(!furtherPayment)} className="btn bg-gray-300 hover:bg-gray-400 btn-sm">
                                        Abbrechen
                                    </button>
                                </div>
                            </div>
                            {showAll &&
                                displayedTransactions?.map(
                                    (
                                        item: any,
                                        index: number // TODO type ändern
                                    ) => (
                                        <AssignBillElement
                                            func={() => handleClick(item._id)}
                                            key={index}
                                            status={item.processingStatus === "ASSIGNED"}
                                            date={dayjs(item.bookingTimestamp).format("DD.MM.YYYY")}
                                            amount={item.paymentInformation.transactionAmount.amount}
                                            customer={item.paymentInformation.remittanceText}
                                        />
                                    )
                                )}
                            {!showAll &&
                                displayedTransactions
                                    ?.filter((obj: { processingStatus: string }) => obj.processingStatus === "UNASSIGNED")
                                    .map(
                                        (
                                            item: transactionElementType,
                                            index: number // TODO type ändern
                                        ) => (
                                            <AssignBillElement
                                                func={() => handleClick(item._id)}
                                                key={index}
                                                status={item.processingStatus === "ASSIGNED"}
                                                date={dayjs(item.bookingTimestamp).format("DD.MM.YYYY")}
                                                amount={item.paymentInformation.transactionAmount.amount.toString()}
                                                customer={item.paymentInformation.remittanceText}
                                            />
                                        )
                                    )}
                        </>
                    )}
                </div>
            )}
        </BaseModal>
    )
}
