import { useContext, useEffect, useMemo, useState } from "react"
import BasePage from "../../../../components/layout/BasePage"
import BaseHeadBanner from "../../../../components/elements/BaseHeadBanner"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { getSingleObjectData } from "../../api/apiCalls"
import { useParams } from "react-router-dom"
import ErrorPage from "../../../404/ErrorPage"
import Loading from "../../../../components/common/Loading"
import { adressType } from "../../../../data/Types"
import { getSingleCustomerData } from "../../../Kunden/api/apiCalls"
import { ObjectType } from "../../types/types"
import { useAddDocToObjectMutation, usePatchDocObjectMutation, useUpdateObjectMutation } from "../../api/services"
import { ModalContext } from "../../../../components/contexts/ModalContext"
import ChangeCustomerModal from "../../components/ChangeCustomerModal"
import { getBillByObject, getOfferByObject } from "../../../Buchhaltung/api/apiCalls"
import { checkForEmptyValues, compareValues } from "../../../../services/functions"
import BaseAddDocumentModal from "../../../../components/elements/BaseAddDocumentModal"
import ModalCheckAction from "../../../../components/elements/ModalCheckAction"
import { useDownloadDocumentMutation } from "../../../api/services"
import { Roles } from "../../../../auth/RoleMapping"
import { RenderWhenAuthorized } from "../../../../components/authentication/RenderWhenAuthorized"
import ObjectDetailPartRight from "./ObjectDetailPartRight"
import ObjectDetailPartLeft from "./ObjectDetailPartLeft"
import EditLvModal from "../../components/EditLvModal"
import BasePreview from "../../../../components/elements/BasePreview"
import { API } from "../../../../api/constants"
import EditOBVModal from "../../components/EditOBVModal"
export default function ObjektDetailPage() {
    const { id } = useParams()
    const { data, error, isLoading } = useQuery({
        queryKey: ["singleObjectData" + id],
        queryFn: () => getSingleObjectData(id ?? ""),
    })

    const {
        data: customerData,
        error: customerError,
        isLoading: customerIsLoading,
    } = useQuery({
        queryKey: ["singleCustomerData" + data?.customer_id],
        queryFn: () => getSingleCustomerData(data.customer_id ?? ""),
    })
    const {
        data: objectsOffer,
        error: objErrOffer,
        isLoading: loadObjectsOffer,
    } = useQuery({
        queryKey: ["offerObjectData" + id],
        queryFn: () => getOfferByObject(id || ""),
    })
    const {
        data: objectsBill,
        error: objErrBill,
        isLoading: loadObjectsBill,
    } = useQuery({
        queryKey: ["billObjectData" + id],
        queryFn: () => getBillByObject(id || ""),
    })
    // get preview of pdf file
    const [selectedS3Key, setselectedS3Key] = useState<string>()
    const objectDownloadUrl = useMemo(() => {
        return error || isLoading || !selectedS3Key ? undefined : API + `/object/${id}/download?s3Key=${selectedS3Key}`
    }, [error, isLoading, selectedS3Key, id])

    const [customer, setcustomer] = useState<{ id: string; name: string }>()
    const [employee, setemployee] = useState(data?.employee_ids)
    const [adress, setadress] = useState<adressType>(data?.adress)
    const [ranking, setranking] = useState(data?.ranking)
    const [unitperentrance, setunitperentrance] = useState(data?.objectInfo?.unitperentrance)
    const [amount, setAmount] = useState(data?.objectInfo?.amount)
    const [propertyArea, setpropertyArea] = useState(data?.objectInfo?.propertyArea)
    const [contractStart, setcontractStart] = useState(data?.objectInfo?.contractStart)
    const [contractEnd, setcontractEnd] = useState(data?.objectInfo?.contractEnd)
    const [lastPriceChange, setlastPriceChange] = useState(data?.objectInfo?.lastPriceChange)
    const [lastContractChange, setlastContractChange] = useState(data?.objectInfo?.lastContractChange)
    const [notes, setnotes] = useState(data?.objectInfo?.notes)
    const queryClient = useQueryClient()
    const modalCon = useContext(ModalContext)

    // add file
    const { mutate: addDoc, isPending: pendingAddFile } = useAddDocToObjectMutation(queryClient)

    // download file from object
    const { mutate: downloadFile, isPending: pendingDownloadFile } = useDownloadDocumentMutation()
    const handleDownload = (s3Key: string, filename: string) => {
        downloadFile({ id: data._id, s3Key: s3Key, filetype: "object", filename: filename })
    }

    // delete file
    const [fileToDelete, setfileToDelete] = useState<string>("")
    const { mutate: deleteDoc, isPending: pendingDeleteFile } = usePatchDocObjectMutation(queryClient, modalCon, 4)
    const handleDeleteDoc = () => {
        deleteDoc({
            id: id ?? "",
            file: fileToDelete,
        })
    }

    // right side set data useEffect
    useEffect(() => {
        if (data) {
            setemployee(data?.employee_ids)
            setadress(data?.adress)
            setranking(data?.ranking)
            setunitperentrance(data?.objectInfo?.unitperentrance)
            setAmount(data?.objectInfo?.amount)
            setpropertyArea(data?.objectInfo?.propertyArea)
            setcontractStart(data?.objectInfo?.contractStart)
            setcontractEnd(data?.objectInfo?.contractEnd)
            setlastPriceChange(data?.objectInfo?.lastPriceChange)
            setlastContractChange(data?.objectInfo?.lastContractChange)
            setnotes(data?.objectInfo?.notes)
        }
    }, [data])

    // left side set data useEffect
    useEffect(() => {
        if (data?.employee_ids) {
            setemployee(data?.employee_ids)
        }
        if (customerData && !customerIsLoading) {
            setcustomer({
                id: customerData?.customer?._id,
                name: customerData?.customer?.customer?.name + " | " + customerData?.customer?.administrator?.name,
            })
        }
    }, [data?.employee_ids, customerData, customerIsLoading])

    // update object
    const { mutate: updateObjectQuery, isPending: pendingUpdateObject } = useUpdateObjectMutation(queryClient)
    const handleUpdateObject = () => {
        const updatedObjectData: ObjectType = {
            adress: adress ?? data?.adress,
            customer_id: customer?.id ?? customerData?.customer?._id ?? data?.customer_id,
            ranking: ranking ?? data.ranking,
            objectInfo: {
                unitperentrance: unitperentrance ?? data?.objectInfo?.unitperentrance,
                amount: amount ?? data?.objectInfo?.amount,
                propertyArea: propertyArea ?? data?.objectInfo?.propertyArea,
                contractStart: contractStart ?? data?.objectInfo?.contractStart,
                contractEnd: contractEnd ?? data?.objectInfo?.contractEnd,
                lastPriceChange: lastPriceChange ?? data?.objectInfo?.lastPriceChange,
                lastContractChange: lastContractChange ?? data?.objectInfo?.lastContractChange,
                notes: notes ?? data?.objectInfo?.notes,
            },
        }
        updateObjectQuery({
            id: id ?? "",
            body: updatedObjectData,
        })
    }

    return (
        <BasePage>
            {(error || (data?.customer_id ? customerError : false) || objErrBill || objErrOffer) && <ErrorPage />}
            {(isLoading ||
                pendingAddFile ||
                pendingDeleteFile ||
                pendingDownloadFile ||
                pendingUpdateObject ||
                (data?.customer_id ? customerIsLoading : false) ||
                loadObjectsBill ||
                loadObjectsOffer) && <Loading />}
            {data &&
                objectsOffer &&
                objectsBill &&
                !error &&
                !isLoading &&
                (data?.customer_id ? customerData && !customerError && !customerIsLoading : true) && (
                    <>
                        <BaseHeadBanner
                            title={
                                data.adress.street +
                                " " +
                                data.adress.nr +
                                ", " +
                                data.adress.plz +
                                " " +
                                data.adress.city +
                                " | Nummer: " +
                                (data?.nr ?? "404")
                            }
                            button={
                                <RenderWhenAuthorized requiresAll={[Roles.objekte_read_write]}>
                                    <div className="hidden md:flex flex-row w-fit items-center gap-2">
                                        <input
                                            type="radio"
                                            name="radio-5"
                                            value={1}
                                            className="radio radio-success"
                                            checked={(ranking ?? data?.ranking) === 1}
                                            onChange={(e) => setranking(Number(e.target.value))}
                                        />
                                        <input
                                            type="radio"
                                            name="radio-5"
                                            value={2}
                                            className="radio radio-warning"
                                            checked={(ranking ?? data?.ranking) === 2}
                                            onChange={(e) => setranking(Number(e.target.value))}
                                        />
                                        <input
                                            type="radio"
                                            name="radio-5"
                                            value={3}
                                            className="radio radio-error"
                                            checked={(ranking ?? data?.ranking) === 3}
                                            onChange={(e) => setranking(Number(e.target.value))}
                                        />

                                        <button
                                            disabled={
                                                checkForEmptyValues([adress?.street, adress?.nr, adress?.plz, adress?.city]) ||
                                                !compareValues([
                                                    {
                                                        v1: ranking,
                                                        v2: data?.ranking,
                                                    },
                                                    {
                                                        v1: unitperentrance,
                                                        v2: data?.objectInfo?.unitperentrance,
                                                    },
                                                    {
                                                        v1: amount,
                                                        v2: data?.objectInfo?.amount,
                                                    },
                                                    {
                                                        v1: propertyArea,
                                                        v2: data?.objectInfo?.propertyArea,
                                                    },
                                                    {
                                                        v1: contractStart,
                                                        v2: data?.objectInfo?.contractStart,
                                                    },
                                                    {
                                                        v1: contractEnd,
                                                        v2: data?.objectInfo?.contractEnd,
                                                    },
                                                    {
                                                        v1: lastPriceChange,
                                                        v2: data?.objectInfo?.lastPriceChange,
                                                    },
                                                    {
                                                        v1: lastContractChange,
                                                        v2: data?.objectInfo?.lastContractChange,
                                                    },
                                                    {
                                                        v1: notes,
                                                        v2: data?.objectInfo?.notes,
                                                    },
                                                    {
                                                        v1: adress,
                                                        v2: data?.adress,
                                                    },
                                                    {
                                                        v1: customer?.id,
                                                        v2: data?.customer_id,
                                                    },
                                                ])
                                            }
                                            className="btn btn-success ml-4"
                                            onClick={handleUpdateObject}>
                                            Speichern
                                        </button>
                                    </div>
                                </RenderWhenAuthorized>
                            }
                        />
                        <section className="flex flex-row w-full gap-4 pt-4">
                            {/* LEFT */}
                            <RenderWhenAuthorized requiresAll={[Roles.objekte_read_write]}>
                                <ObjectDetailPartLeft
                                    s3RefIsExisting={data?.s3ImageRef?.s3Key !== undefined}
                                    id={data._id}
                                    lvdoesnotexist={!data.listOfServices}
                                    obvdoesexist={!data.serviceContract.created}
                                    name={customer?.name}
                                    objectsOffer={objectsOffer}
                                    objectsBill={objectsBill}
                                />
                            </RenderWhenAuthorized>

                            {/* RIGHT */}
                            <ObjectDetailPartRight
                                setselectedS3Key={setselectedS3Key}
                                unitperentrance={unitperentrance}
                                setunitperentrance={setunitperentrance}
                                amount={amount}
                                setAmount={setAmount}
                                propertyArea={propertyArea}
                                setpropertyArea={setpropertyArea}
                                contractStart={contractStart}
                                setcontractStart={setcontractStart}
                                contractEnd={contractEnd}
                                setcontractEnd={setcontractEnd}
                                lastPriceChange={lastPriceChange}
                                setlastPriceChange={setlastPriceChange}
                                notes={notes}
                                setnotes={setnotes}
                                lastContractChange={lastContractChange}
                                setlastContractChange={setlastContractChange}
                                adress={adress}
                                setadress={setadress}
                                handleDownload={handleDownload}
                                setfileToDelete={setfileToDelete}
                                handleUpdateObject={handleUpdateObject}
                                documents={data.s3Documents}
                            />
                        </section>
                        <ChangeCustomerModal
                            modalId={0}
                            customer={customer ?? {}}
                            setcustomer={setcustomer}
                            isCustomerChanged={customer?.id === data?.customer_id}
                        />
                        <BaseAddDocumentModal addDoc={addDoc} closeModal={() => modalCon?.trigger(3)} modalId={3} selectedId={id ?? ""} />
                        <ModalCheckAction
                            modalId={4}
                            headline={"Dokument löschen?"}
                            text={"Sind Sie sich sicher, dass Sie dieses Dokument wollen?"}
                            buttonText={"Löschen"}
                            buttonColor="btn-error"
                            func={handleDeleteDoc}
                        />
                        <EditLvModal modalId={5} objectId={data._id} data={data.listOfServices} />
                        <BasePreview title={"Dokumentvorschau"} sidePanelId={6} previewType={"document"} content={objectDownloadUrl} />
                        {!data.serviceContract.created && (
                            <EditOBVModal
                                modalId={7}
                                objectId={data._id}
                                data={data.listOfServices}
                                object={data.adress.street + " " + data.adress.nr + ", " + data.adress.plz + " " + data.adress.city}
                                customer={customerData.customer}
                            />
                        )}
                    </>
                )}
        </BasePage>
    )
}
