import { notification } from "antd"

export const messageSuccess = (head: string, text: string) => {
    notification.success({
        message: head,
        description: text,
        placement: "bottomLeft",
    })
}
export const messageInfo = (head: string, text: string) => {
    notification.info({
        message: head,
        description: text,
        placement: "bottomLeft",
    })
}
export const messageError = (head: string, text: string) => {
    notification.error({
        message: head,
        description: text,
        placement: "bottomLeft",
    })
}
