import { useContext, useState } from "react"
import BaseInputField from "../../../components/elements/BaseInputField"
import BaseModal from "../../../components/layout/BaseModal"
import { Employee } from "../types/Types"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { ModalContext } from "../../../components/contexts/ModalContext"
import BaseInputArea from "../../../components/elements/BaseInputArea"
import { Roles } from "../../../auth/RoleMapping"
import { RenderWhenAuthorized } from "../../../components/authentication/RenderWhenAuthorized"
import { getUserGroupsFromKC } from "../api/apiCalls"
import Loading from "../../../components/common/Loading"
import ErrorPage from "../../404/ErrorPage"
import BaseSelection from "../../../components/elements/BaseSelection"
import { validateEmail } from "../../../services/functions"
import { usePostDataMutation } from "../../api/services"

export default function AddPersonModal() {
    const [first_name, setfirst_name] = useState("")
    const [last_name, setlast_name] = useState("")
    const [geb, setgeb] = useState("")
    const [email, setemail] = useState("")
    const [contractStart, setcontractStart] = useState("")
    const [notes, setnotes] = useState("")
    const [position, setposition] = useState("Mitarbeiter")
    const [kostenstelleData, setKostenstelle] = useState("Dresden")

    const queryClient = useQueryClient()
    const modalCon = useContext(ModalContext)

    const { data, error, isLoading } = useQuery({
        queryKey: ["kcUserGroups"],
        queryFn: getUserGroupsFromKC,
    })

    const clearInput = () => {
        setfirst_name("")
        setlast_name("")
        setgeb("")
        setemail("")
        setposition("Mitarbeiter")
        setKostenstelle("Dresden")
        setnotes("")
    }

    // ADD Functions
    const { mutate: addEmployee, isPending: pendingAddEmployee } = usePostDataMutation(
        queryClient,
        modalCon,
        "Mitarbeiter",
        "employeeData1",
        clearInput
    )
    const handleNewEmployee = () => {
        const newEmployeeData: Employee = {
            first_name: first_name,
            last_name: last_name,
            geb: geb,
            email: email,
            position: position,
            contractStart: contractStart,
            vehicle: false,
            trailperiod: false,
            kostenstelle: kostenstelleData,
            notes: notes ?? undefined,
        }
        addEmployee({ body: newEmployeeData, pageType: "employee" })
    }

    return (
        <RenderWhenAuthorized requiresAll={[Roles.mitarbeiter_create]}>
            <BaseModal
                title="Neuen Mitarbeiter hinzufügen"
                modalId={1}
                bottomRow={
                    <div className="flex flex-row justify-end">
                        <button
                            onClick={handleNewEmployee}
                            className="btn btn-success self-end"
                            disabled={
                                !validateEmail(email) ||
                                first_name === "" ||
                                last_name === "" ||
                                geb === "" ||
                                email === "" ||
                                position === "" ||
                                contractStart === "" ||
                                kostenstelleData === ""
                            }>
                            Speichern
                        </button>
                    </div>
                }>
                {(isLoading || pendingAddEmployee) && <Loading />}
                {error && <ErrorPage />}
                {!error && !isLoading && data && (
                    <>
                        <BaseInputField label={"Vorname *"} type={"text"} value={first_name} onChange={setfirst_name} classProps="pt-4" />
                        <BaseInputField label={"Nachname *"} type={"text"} value={last_name} onChange={setlast_name} classProps="pt-2" />
                        <BaseInputField
                            label={"E-Mail-Adresse *"}
                            type={"email"}
                            value={email}
                            status={!validateEmail(email) && email !== "" ? "error" : undefined}
                            onChange={setemail}
                            classProps="pt-2"
                        />
                        <BaseInputField label={"Geburtstag *"} type={"date"} value={geb} onChange={setgeb} classProps="pt-2" />
                        <BaseSelection
                            classProps="pt-2"
                            label="Rolle *"
                            data={data.rbac.map((item: { id: string; name: string }) => item.name)}
                            setId={setposition}
                            value={position}
                            placeholder="Rolle auswählen"
                        />
                        <BaseInputField label="Vertragsbeginn *" type="date" value={contractStart} onChange={setcontractStart} classProps="pt-2" />
                        <BaseSelection
                            classProps="pt-2"
                            label="Kostenstelle *"
                            data={data.ou.map((item: { id: string; name: string }) => item.name)}
                            setId={setKostenstelle}
                            value={kostenstelleData}
                            placeholder="Kostenstelle auswählen"
                        />
                        <BaseInputArea label={"Bemerkung"} value={notes} onChange={setnotes} classProps="pt-2" />
                    </>
                )}
                {/* <BaseDivider />
        <div className="flex flex-row gap-4">
          <BaseSelection input={"Rolle auswählen"} selectData={roleData} />
          <PopUpObjectListButton
            label="Objekte zuweisen"
            showElements={showObjects}
            setshowElements={() => setshowObjects(!showObjects)}
          />
        </div>
        <PopUpObjectList
          visible={showObjects}
          setVisible={() => setshowObjects(false)}
          elementType="objects"
          elements={objectData}
        /> */}
            </BaseModal>
        </RenderWhenAuthorized>
    )
}
