import React, { useContext, useState } from "react"
import BaseModal from "../../../../components/layout/BaseModal"
import { useQueryClient } from "@tanstack/react-query"
import { ModalContext } from "../../../../components/contexts/ModalContext"
import { useAddBillMutation } from "../../api/services"
import { BillFromOfferType, BillPost } from "../../../../data/Types"
import dayjs from "dayjs"
import BaseInputField from "../../../../components/elements/BaseInputField"
import BaseSelection from "../../../../components/elements/BaseSelection"
import Loading from "../../../../components/common/Loading"

export default function BillFromOfferModal({ customer, object, positions, offer, documents }: BillFromOfferType) {
    const [billType, setbillType] = useState("Rechnung")

    const [billCategorie, setbillCategorie] = useState("Hausmeister")
    const [billDue, setbillDue] = useState<string>("28")
    const [billDay, setbillDay] = useState<string>("15")
    const [deliveryDate, setdeliveryDate] = useState("")

    const clearInput = () => {
        setbillCategorie("Hausmeister")
        setbillDay("15")
        setbillDue("28")
        setdeliveryDate("")
        setbillType("Rechnung")
    }

    const queryClient = useQueryClient()
    const modalCon = useContext(ModalContext)
    const { mutate: addBill, isPending: pendingAddBill } = useAddBillMutation(queryClient, modalCon, clearInput, offer)
    const handleNewBill = () => {
        const NewBill: BillPost = {
            date: Date(),
            customer_id: customer._id,
            object_id: object._id,
            offer_id: offer,
            positions: positions,
            billCategorie: billCategorie,
            billType: billType,
            billDue: Number(billDue),
            serviceDate: dayjs(deliveryDate).format("DD.MM.YYYY"),
        }
        if (billType !== "Rechnung") {
            NewBill.billDay = Number(billDay)
        }
        const formData = new FormData()
        formData.append("bill", JSON.stringify(NewBill))
        documents?.forEach((doc: any) => {
            if (doc) {
                formData.append("additionalDocuments", doc)
            }
        })
        addBill(formData)
    }

    return (
        <BaseModal
            modalId={1}
            title={"Rechnung stellen"}
            bottomRow={
                <div className="flex justify-end items-center">
                    <button className="btn btn-success" onClick={handleNewBill} disabled={deliveryDate === ""}>
                        Rechnung stellen
                    </button>
                </div>
            }>
            {pendingAddBill ? (
                <Loading />
            ) : (
                <>
                    <div className="flex gap-4">
                        <BaseSelection
                            label={"Rechnungstyp *"}
                            placeholder={""}
                            classProps="mt-4"
                            data={["Rechnung", "Jahresrechnung", "Dauerrechnung"]}
                            setId={setbillType}
                            defaultValue="Rechnung"
                        />
                        <BaseSelection
                            label={"Kategorie *"}
                            placeholder={""}
                            classProps="mt-4 !w-64"
                            data={["Hausmeister", "Schlüsseldienst", "Bau"]}
                            setId={setbillCategorie}
                            defaultValue="Hausmeister"
                        />
                    </div>
                    <div className="flex flex-col w-full mt-4">
                        <label className="text-sm pl-2 pb-1">Kunde *</label>
                        <p className="p-4 border-2 border-black rounded-md">{customer.name}</p>
                    </div>
                    <div className="flex flex-col w-full mt-4">
                        <label className="text-sm pl-2 pb-1">Objekt *</label>
                        <p className="p-4 border-2 border-black rounded-md">{object.adress}</p>
                    </div>
                    <BaseInputField label={"Leistungsdatum"} type={"date"} value={deliveryDate} onChange={setdeliveryDate} classProps="mt-2" />
                    <BaseSelection
                        label={"Rechnungsfrist in Tagen *"}
                        placeholder={""}
                        classProps="mt-2"
                        data={["7", "14", "28"]}
                        setId={setbillDue}
                        defaultValue="28"
                    />
                    {billType !== "Rechnung" && (
                        <BaseSelection
                            label={"Zahltag des Monats *"}
                            placeholder={""}
                            classProps="mt-2"
                            data={["1", "15", "28"]}
                            setId={setbillDay}
                            defaultValue="28"
                        />
                    )}
                </>
            )}
        </BaseModal>
    )
}
