import { useMutation } from "@tanstack/react-query"
import { resendMail } from "./apiCalls"
import { messageError, messageSuccess } from "../messages"

export const useResendMailMutation = () => {
    return useMutation<unknown, Error, string>({
        mutationFn: resendMail,
        onSuccess: () => {
            messageSuccess("Fertig!", "Die Mail wurde erfolgreich versandt.")
        },
        onError: (err) => {
            messageError("Fehler!", "Die Mail konnte nicht neu versandt werden.")
            console.log(err)
        },
    })
}
