import { QueryClient, useMutation } from "@tanstack/react-query"
import { archiveDataGeneral, deleteDataGeneral, getDocumentFromAnyType, postNewDataGeneral } from "./apiCalls"
import { ArchiveDataParams, DeleteDataParams, PostNewDataParams } from "./Types/Types"
import { messageError, messageSuccess } from "../../services/messages"

// download document
export const useDownloadDocumentMutation = () => {
    return useMutation<Blob, Error, { id: string; s3Key: string; filetype: string; filename: string }>({
        mutationFn: getDocumentFromAnyType,
        onSuccess: (data, variables) => {
            const url = window.URL.createObjectURL(data)
            const a = document.createElement("a")
            a.href = url
            a.download = variables.filename
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(url)
            a.remove()
            messageSuccess("Fertig!", "Das Dokument wurde erfolgreich heruntergeladen.")
        },
        onError: (err) => {
            messageError("Fehler!", "Das Dokument konnte nicht heruntergeladen werden.")
            console.log(err)
        },
    })
}

// post function - add customer / object / employee
export const usePostDataMutation = (queryClient: QueryClient, modalCon: any, pageType: string, refreshData: string, clearInput: () => void) => {
    return useMutation<unknown, Error, PostNewDataParams>({
        mutationFn: postNewDataGeneral,
        onSuccess: () => {
            clearInput()
            modalCon?.trigger(0)
            messageSuccess("Fertig!", pageType + " wurde erfolgreich erstellt.")
            queryClient.invalidateQueries({
                queryKey: [refreshData],
            })
        },
        onError: (err) => {
            messageError("Fehler!", pageType + " konnte nicht erstellt werden.")
            console.log(err)
        },
    })
}

// delete function - delete customer / object / employee
export const useDeleteDataMutation = (queryClient: QueryClient, modalCon: any, pageType: string, refreshData: string) => {
    return useMutation<unknown, Error, DeleteDataParams>({
        mutationFn: deleteDataGeneral,
        onSuccess: () => {
            messageSuccess("Fertig!", pageType + " wurde erfolgreich gelöscht.")
            modalCon?.trigger(4)
            queryClient.invalidateQueries({
                queryKey: [refreshData],
            })
        },
        onError: (err) => {
            messageError("Fehler!", pageType + " konnte nicht gelöscht werden.")
            console.log(err)
        },
    })
}

// archive function - update customer / object / employee
export const useArchiveDataMutation = (queryClient: QueryClient, modalConFunction: () => void, refreshData: string, pageType: string) => {
    return useMutation<unknown, Error, ArchiveDataParams>({
        mutationFn: archiveDataGeneral,
        onSuccess: () => {
            messageSuccess("Fertig!", pageType + " wurde erfolgreich archiviert.")
            modalConFunction()
            queryClient.invalidateQueries({ queryKey: [refreshData] })
        },
        onError: (err) => {
            messageError("Fehler!",  pageType + " konnte nicht archiviert werden.")
            console.log(err)
        },
    })
}

