import { QueryClient, useMutation } from "@tanstack/react-query"
import { postNewOffer, statusOffer, archiveOffer, postNewBill, archiveBill, cancelBill, solvePaymentEscalation } from "./apiCalls"
import { messageError, messageSuccess } from "../../../services/messages"

// ADD Offer
export const useAddOfferMutation = (queryClient: QueryClient, modalCon: any, clearInput: () => void) => {
    return useMutation<unknown, Error, any>({
        mutationFn: (variables) => postNewOffer(variables.body, variables.leistungsVerzeichnis),
        onSuccess: () => {
            messageSuccess("Fertig!", "Das Angebot wurde erfolgreich erstellt.")
            clearInput()
            modalCon?.trigger(0)
            queryClient.invalidateQueries({
                queryKey: ["offerData1"],
            })
        },
        onError: (err) => {
            clearInput()
            modalCon?.trigger(0)
            messageError("Fehler!", "Das Angebot konnte nicht erstellt werden.")
            console.log(err)
        },
    })
}

export const useUpdateStatusOfferMutation = (queryClient: QueryClient) => {
    return useMutation<unknown, Error, { id: string; body: { status: string } }>({
        mutationFn: statusOffer,
        onSuccess: (data, variables) => {
            messageSuccess("Fertig!", "Der Status des Angebots wurde erfolgreich aktualisiert.")
            queryClient.invalidateQueries({
                queryKey: ["singleOffer" + variables?.id],
            })
        },
        onError: (err) => {
            messageError("Fehler!", "Der Status des Angebots konnte nicht aktualisiert werden.")
            console.log(err)
        },
    })
}

export const useArchiveOfferMutation = (queryClient: QueryClient, modalCon: any, modalId: number) => {
    return useMutation<
        unknown,
        Error,
        {
            id: string
            body: { archived: boolean }
        }
    >({
        mutationFn: archiveOffer,
        onSuccess: (data, variables) => {
            messageSuccess("Fertig!", "Das Angebot wurde erfolgreich archiviert.")
            modalCon?.trigger(modalId)
            queryClient.invalidateQueries({
                queryKey: ["singleOffer" + variables?.id],
            })
        },
        onError: (err) => {
            messageError("Fehler!", "Das Angebot konnte nicht archiviert werden.")
            console.log(err)
        },
    })
}

// ====================================================== BILL ======================================================  //

export const useAddBillMutation = (queryClient: QueryClient, modalCon: any, clearInput: () => void, offer_id?: string) => {
    return useMutation<unknown, Error, any>({
        mutationFn: postNewBill,
        onSuccess: () => {
            clearInput()
            modalCon?.trigger(0)
            messageSuccess("Fertig!", "Die Rechnung wurde erfolgreich erstellt.")
            queryClient.invalidateQueries({
                queryKey: ["billData1"],
            })
            queryClient.invalidateQueries({
                queryKey: ["paymentsData1"],
            })
            queryClient.invalidateQueries({
                queryKey: ["singleOffer" + offer_id],
            })
        },
        onError: (err) => {
            clearInput()
            modalCon?.trigger(0)
            messageError("Fehler!", "Die Rechnung konnte nicht erstellt werden.")
            console.log(err)
        },
    })
}

export const useArchiveBillMutation = (queryClient: QueryClient, modalCon: any, modalId: number) => {
    return useMutation<
        unknown,
        Error,
        {
            id: string
            body: { archived: boolean }
        }
    >({
        mutationFn: archiveBill,
        onSuccess: (data, variables) => {
            messageSuccess("Fertig!", "Die Rechnung wurde erfolgreich archiviert.")
            modalCon?.trigger(modalId)
            queryClient.invalidateQueries({
                queryKey: ["singleBill" + variables?.id],
            })
        },
        onError: (err) => {
            messageError("Fehler!", "Die Rechnung konnte nicht archiviert werden.")
            console.log(err)
        },
    })
}

export const useCancelBillMutation = (queryClient: QueryClient, modalCon: any, modalId: number) => {
    return useMutation<
        unknown,
        Error,
        {
            id: string
            body: { canceled: boolean }
        }
    >({
        mutationFn: cancelBill,
        onSuccess: (data, variables) => {
            messageSuccess("Fertig!", "Die Rechnung wurde erfolgreich storniert.")
            modalCon?.trigger(modalId)
            queryClient.invalidateQueries({
                queryKey: ["singleBill" + variables?.id],
            })
        },
        onError: (err) => {
            messageError("Fehler!", "Die Rechnung konnte nicht storniert werden.")
            console.log(err)
        },
    })
}

// ====================================================== PAYMENT REQUEST ======================================================  //
export const useSolveEscalationMutation = (queryClient: QueryClient, modalCon: any, modalId: number) => {
    return useMutation<unknown, Error, String>({
        mutationFn: solvePaymentEscalation,
        onSuccess: (data, variables) => {
            messageSuccess("Fertig!", "Der Mahnungsprozess wurde erfolgreich aktualisiert.")
            modalCon?.trigger(modalId)
            queryClient.invalidateQueries({
                queryKey: ["paymentsData"],
            })
        },
        onError: (err) => {
            messageError("Fehler!", "Der Mahnungsprozess konnte nicht aktualisiert werden.")
            console.log(err)
        },
    })
}
