import { useEffect, useState } from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import Header from "./components/common/Drawer"
import Loading from "./components/common/Loading"
import BaseLayout from "./components/layout/BaseLayout"
import Dashboard from "./pages/Dashboard/pages/Dashboard"
import ErrorPage from "./pages/404/ErrorPage"
import PaymentPage from "./pages/Buchhaltung/pages/PaymentPage"
import Aufgaben from "./pages/Aufgaben/Aufgaben"
import Mitarbeiter from "./pages/Mitarbeiter/pages/Mitarbeiter"
import Objekte from "./pages/Objekte/pages/Objekte"
import "./style/scroll.css"
import BillDetailPage from "./pages/Buchhaltung/pages/billDetail/BillDetailPage"
import Kunden from "./pages/Kunden/pages/Kunden"
import OfferPage from "./pages/Buchhaltung/pages/OfferPage"
import OfferDetailPage from "./pages/Buchhaltung/pages/offerDetail/OfferDetailPage"
import CustomerDetailPage from "./pages/Kunden/pages/CustomerDetailPage"
import ObjektDetailPage from "./pages/Objekte/pages/objectDetail/ObjektDetailPage"
import Hilfe from "./pages/Hilfe/pages/Hilfe"
import BillPage from "./pages/Buchhaltung/pages/BillPage"
import { Roles } from "./auth/RoleMapping"
import { RenderWhenAuthorized } from "./components/authentication/RenderWhenAuthorized"

function App() {
    const [loading, setLoading] = useState(true)

    const handleLoading = () => {
        setLoading(false)
    }

    // this hook is just nessecary as long as there are no loading conditions like axios.get
    useEffect(() => {
        setTimeout(() => {
            handleLoading()
        }, 300)
    })

    return (
        <main className="relative overflow-hidden">
            {loading && <Loading />}
            {!loading && (
                <div className={"flex flex-row w-screen min-h-screen bg-base-100 overflow-hidden"}>
                    <Header />
                    <div className="flex flex-col min-h-full grow w-full overflow-hidden">
                        <Routes>
                            <Route
                                path="/"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[Roles.dashboard_read]}>
                                        <Navigate to="/dashboard" />
                                    </RenderWhenAuthorized>
                                }
                            />
                            <Route path="/loading" element={<BaseLayout title="Logout..." roles={[]} children={<Loading />} />} />
                            {/* Dashboard Routes */}
                            <Route
                                path="/dashboard"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[Roles.dashboard_read]}>
                                        <BaseLayout title="Dashboard" roles={[Roles.dashboard_read]} children={<Dashboard />} />
                                    </RenderWhenAuthorized>
                                }
                            />
                            {/* Verteiler from Payment Requirements Routes */}
                            <Route
                                path="/buchhaltung"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[Roles.buchhaltung_zahlungen_read]}>
                                        <Navigate to="/buchhaltung/zahlungen" />
                                    </RenderWhenAuthorized>
                                }
                            />
                            {/* Payment Requirements Routes */}
                            <Route
                                path="/buchhaltung/zahlungen"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[Roles.buchhaltung_zahlungen_read]}>
                                        <BaseLayout roles={[Roles.buchhaltung_read]} title="Zahlungen" children={<PaymentPage />} />
                                    </RenderWhenAuthorized>
                                }
                            />
                            {/* Bill Route */}
                            <Route
                                path="/buchhaltung/rechnungen"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[Roles.buchhaltung_read]}>
                                        <BaseLayout roles={[Roles.buchhaltung_read]} title="Rechnungen" children={<BillPage />} />
                                    </RenderWhenAuthorized>
                                }
                            />
                            {/* Offer Route */}
                            <Route
                                path="/buchhaltung/angebote"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[Roles.buchhaltung_read]}>
                                        <BaseLayout roles={[Roles.buchhaltung_read]} title="Angebote" children={<OfferPage />} />
                                    </RenderWhenAuthorized>
                                }
                            />
                            {/* Bill Detailed Route */}
                            <Route
                                path="/buchhaltung/rechnung/:id"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[Roles.buchhaltung_read]}>
                                        <BaseLayout roles={[Roles.buchhaltung_read]} title="Rechnungen" children={<BillDetailPage />} />
                                    </RenderWhenAuthorized>
                                }
                            />
                            {/* Offer Detailed Routes */}
                            <Route
                                path="/buchhaltung/angebote/:id"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[Roles.buchhaltung_read]}>
                                        <BaseLayout roles={[Roles.buchhaltung_read]} title="Angebot" children={<OfferDetailPage />} />
                                    </RenderWhenAuthorized>
                                }
                            />
                            {/* Aufgaben Routes */}
                            <Route
                                path="/aufgaben"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[]}>
                                        <BaseLayout roles={[]} title="Aufträge" children={<Aufgaben />} />
                                    </RenderWhenAuthorized>
                                }
                            />
                            {/* Mitarbeiter Routes */}
                            <Route
                                path="/mitarbeiter"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[Roles.mitarbeiter_read, Roles.mitarbeiter_read_ou]}>
                                        <BaseLayout roles={[Roles.mitarbeiter_read]} title="Mitarbeiter" children={<Mitarbeiter />} />
                                    </RenderWhenAuthorized>
                                }
                            />
                            <Route
                                path="/mitarbeiter/:id"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[Roles.mitarbeiter_read, Roles.mitarbeiter_read_ou]}>
                                        <BaseLayout roles={[Roles.mitarbeiter_read]} title="Mitarbeiter" children={<Mitarbeiter />} />
                                    </RenderWhenAuthorized>
                                }
                            />
                            {/* Object Route */}
                            <Route
                                path="/objekte"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[Roles.objekte_read]}>
                                        <BaseLayout roles={[Roles.objekte_read]} title="Objekte" children={<Objekte />} />
                                    </RenderWhenAuthorized>
                                }
                            />
                            {/* Object Detailed Route */}
                            <Route
                                path="/objekte/:id"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[Roles.objekte_read]}>
                                        <BaseLayout roles={[Roles.objekte_read]} title="Objekte" children={<ObjektDetailPage />} />
                                    </RenderWhenAuthorized>
                                }
                            />
                            {/* Customer Route */}
                            <Route
                                path="/kunden"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[Roles.kunden_read]}>
                                        <BaseLayout roles={[Roles.kunden_read]} title="Kunden" children={<Kunden />} />
                                    </RenderWhenAuthorized>
                                }
                            />
                            {/* Customer Detailed Route */}
                            <Route
                                path="/kunden/:id"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[Roles.kunden_read]}>
                                        <BaseLayout roles={[Roles.kunden_read]} title="Kunden" children={<CustomerDetailPage />} />
                                    </RenderWhenAuthorized>
                                }
                            />
                            {/* Help Section */}
                            <Route
                                path="/hilfe"
                                element={
                                    <RenderWhenAuthorized page requiresAll={[Roles.help_read_write]}>
                                        <BaseLayout roles={[Roles.help_read_write]} title="Hilfe" children={<Hilfe />} />
                                    </RenderWhenAuthorized>
                                }
                            />
                            {/* Errorpage */}
                            <Route path="/404" element={<BaseLayout roles={[]} title="Page Not Found" children={<ErrorPage />} />} />
                            <Route path="*" element={<Navigate to="/404" />} />
                        </Routes>
                    </div>
                </div>
            )}
        </main>
    )
}

export default App
