import { useContext } from "react"
import { QueryClient, useMutation } from "@tanstack/react-query"
import { setReminder, updateTransactionAndBillData, updateTransactionAndBillPullData } from "./apiCalls"
import { messageError, messageSuccess } from "../../../services/messages"

export const useUpdateTransactionMutation = (queryClient: QueryClient, modalCon: any, modalClose?: boolean) => {
    return useMutation<
        unknown,
        Error,
        {
            pid: string
            tid: string
        }
    >({
        mutationFn: updateTransactionAndBillData,
        onSuccess: () => {
            messageSuccess("Fertig!", "Transaktionen wurden erfolgreich aktualisiert.")

            modalCon?.trigger(1)
            queryClient.invalidateQueries({
                queryKey: ["paymentsData"],
            })
            queryClient.invalidateQueries({
                queryKey: ["transactionData"],
            })
        },

        onError: (err) => {
            messageError("Fehler!", "Transaktionen konnten nicht aktualisiert werden.")
            console.log(err)
        },
    })
}

export const useUpdateTransactionPullMutation = (queryClient: QueryClient, modalCon: any) => {
    return useMutation<
        unknown,
        Error,
        {
            pid: string
            tid: string
        }
    >({
        mutationFn: updateTransactionAndBillPullData,
        onSuccess: () => {
            messageSuccess("Fertig!", "Der Zahlungsstatus wurde erfolgreich aktualisiert.")
            modalCon?.trigger(1)
            queryClient.invalidateQueries({
                queryKey: ["paymentsData"],
            })
            queryClient.invalidateQueries({
                queryKey: ["transactionData"],
            })
        },

        onError: (err) => {
            messageError("Fehler!", "Der Zahlungsstatus konnte nicht aktualisiert werden.")
            console.log(err)
        },
    })
}

export const useSetReminderMutation = (queryClient: QueryClient, modalCon: any, modalId: number, billId: string) => {
    return useMutation<unknown, Error, String>({
        mutationFn: setReminder,
        onSuccess: (data, variables) => {
            messageSuccess("Fertig!", "Der Status der Mahnung wurde erfolgreich aktualisiert.")

            modalCon?.trigger(modalId)
            queryClient.invalidateQueries({
                queryKey: ["paymentsData"],
            })
            queryClient.invalidateQueries({
                queryKey: ["PaymentRequirements" + billId],
            })
        },
        onError: (err) => {
            messageError("Fehler!", "Der Status der Mahnung konnte nicht aktualisiert werden.")
            console.log(err)
        },
    })
}
